/* eslint-disable no-unused-vars */
import { $t } from '@/i18n/index'

// 全部 操作符数据(包含枚举，数字)
export const allOperatorOptions = [
  { label: 'Is', value: 'CONTAINS', icon: 'is', type: 'enum' },
  { label: 'Is not ', value: 'UNCONTAINS', icon: 'is-not', type: 'enum' },
  { label: 'Equal to', value: 'EQ', icon: 'equal-to', type: 'number' },
  { label: 'More than', value: 'GT', icon: 'more-than', type: 'number' },
  { label: 'Less than', value: 'LT', icon: 'less-than', type: 'number' },
  { label: 'Within range', value: 'BETWEEN', icon: 'within-range', type: 'number' },
  { label: 'Outside range', value: 'UNBETWEEN', icon: 'outside-range', type: 'number' },
  { label: 'Is empty', value: 'IS_NULL', icon: 'is-empty', type: 'number' },
  { label: 'Is not empty', value: 'IS_NOT_NULL', icon: 'is-not-empty', type: 'number' }
]

export const transactionType = [
  {
    label: $t('common.onChain'),
    type: 'Chain',
    value: 'CHAIN',
    list: [
      { label: $t('common.transferIn'), value: 'CHAIN_TRANSFER_IN', icon: 'inflow-arrow' },
      { label: $t('common.transferOut'), value: 'CHAIN_TRANSFER_OUT', icon: 'outflow-arrow' },
      {
        label: $t('common.transactionFee'),
        value: 'CHAIN_TRANSACTION_FEE',
        icon: 'outflow-arrow'
      },
      { label: $t('common.withdraw'), value: 'CHAIN_BLOCK_VALIDATOR_WITHDRAW', icon: 'outflow-arrow' },
      { label: $t('common.reward'), value: 'CHAIN_BLOCK_REWARD', icon: 'outflow-arrow' }
    ]
  },
  {
    label: $t('common.bank'),
    type: 'Bank',
    value: 'BANK',
    list: [
      {
        label: $t('common.deposit'),
        value: 'BANK_DEPOSIT',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.withdraw'),
        value: 'BANK_WITHDRAW',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.outgoingFX'),
        value: 'BANK_OUTGOING_FX',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.incomingFX'),
        value: 'BANK_INCOMING_FX',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.tradeIn'),
        value: 'BANK_TRADE_IN',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.tradeOut'),
        value: 'BANK_TRADE_OUT',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.bankFee'),
        value: 'BANK_FEE',
        icon: 'money'
      }
    ]
  },
  {
    label: $t('common.exchange'),
    type: 'Exchange',
    value: 'EXCHANGE',
    list: [
      {
        label: $t('common.deposit'),
        value: 'EXCHANGE_DEPOSIT',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.withdraw'),
        value: 'EXCHANGE_WITHDRAW',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.tradeIn'),
        value: 'EXCHANGE_TRADE_IN',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.tradeOut'),
        value: 'EXCHANGE_TRADE_OUT',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.exchangeFee'),
        value: 'EXCHANGE_FEE',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.stake'),
        value: 'EXCHANGE_STAKE',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.unstake'),
        value: 'EXCHANGE_UNSTAKE',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.reward'),
        value: 'EXCHANGE_REWARD',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.realizedPnL'),
        value: 'EXCHANGE_REALIZED_PNL',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.borrow'),
        value: 'EXCHANGE_BORROW',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.repay'),
        value: 'EXCHANGE_REPAY',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.fundingFee'),
        value: 'EXCHANGE_FUNDING_FEE',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.transferIn'),
        value: 'EXCHANGE_TRANSFER_IN',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.transferOut'),
        value: 'EXCHANGE_TRANSFER_OUT',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.rebate'),
        value: 'EXCHANGE_REBATE',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.internalTransferIn'),
        value: 'EXCHANGE_INNER_IN',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.internalTransferOut'),
        value: 'EXCHANGE_INNER_OUT',
        icon: 'outflow-arrow'
      }
    ]
  },
  {
    label: $t('common.custody'),
    type: 'Custody',
    value: 'CUSTODY',
    list: [
      { label: $t('common.deposit'), value: 'CUSTODY_DEPOSIT', icon: 'inflow-arrow' },
      {
        label: $t('common.withdraw'),
        value: 'CUSTODY_WITHDRAW',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.tradeIn'),
        value: 'CUSTODY_TRADE_IN',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.tradeOut'),
        value: 'CUSTODY_TRADE_OUT',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.custodyFee'),
        value: 'CUSTODY_FEE',
        icon: 'outflow-arrow'
      }
    ]
  },
  {
    label: $t('common.payment'),
    type: 'Payment',
    value: 'PAYMENT',
    list: [
      {
        label: $t('common.completed'),
        value: 'PAYMENT_COMPLETED',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.chargeBack'),
        value: 'PAYMENT_CHARGE_BACK',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.refund'),
        value: 'PAYMENT_REFUND',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.payout'),
        value: 'PAYMENT_PAYOUT',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.capture'),
        value: 'PAYMENT_CAPTURE',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.outgoingFX'),
        value: 'PAYMENT_OUTGOING_FX',
        icon: 'outflow-arrow'
      },
      {
        label: $t('common.incomingFX'),
        value: 'PAYMENT_INCOMING_FX',
        icon: 'inflow-arrow'
      },
      {
        label: $t('common.paymentFee'),
        value: 'PAYMENT_FEE',
        icon: 'outflow-arrow'
      }
    ]
  }
]

export const tradeType = [
  { label: $t('common.futureOpen'), value: 'FUTURE_OPEN', icon: 'shopping--cart--plus' },
  { label: $t('common.futureClose'), value: 'FUTURE_CLOSE', icon: 'shopping--cart--minus' },
  { label: $t('common.optionBuy'), value: 'OPTION_BUY', icon: 'shopping--cart--plus' },
  { label: $t('common.optionSell'), value: 'OPTION_SELL', icon: 'shopping--cart--minus' },
  { label: $t('common.optionExercise'), value: 'OPTION_EXERCISE', icon: 'document_green' },
  { label: $t('common.spotBuy'), value: 'SPOT_BUY', icon: 'shopping--cart--plus' },
  { label: $t('common.spotSell'), value: 'SPOT_SELL', icon: 'shopping--cart--minus' },
  { label: $t('common.foreignExchange'), value: 'FOREIGN_EXCHANGE', icon: 'arrows--horizontal' },
  { label: $t('common.swap'), value: 'SWAP', icon: 'arrows--horizontal' }
]

export const gainLossType = [
  { label: $t('common.futureRealized'), value: 'FUTURE_REALIZED', icon: 'checkmark--outline' },
  { label: $t('common.futureUnrealized'), value: 'FUTURE_UNREALIZED', icon: 'time' },
  { label: $t('common.optionRealized'), value: 'OPTION_REALIZED', icon: 'checkmark--outline' },
  { label: $t('common.optionUnrealized'), value: 'OPTION_UNREALIZED', icon: 'time' }
]

export const positionOptions = [
  {
    label: $t('project.costBasis.long'),
    value: 'LONG',
    svgName: 'arrow--up-right'
  },
  {
    label: $t('project.costBasis.short'),
    value: 'SHORT',
    svgName: 'arrow--down-right'
  }
]

// 全部 操作符数据--仅枚举
export const allEnumOperatorOptions = [
  { label: 'Is', value: 'CONTAINS', icon: 'is', type: 'enum' },
  { label: 'Is not ', value: 'UNCONTAINS', icon: 'is-not', type: 'enum' }
]

export const amountOperatorOptions = [
  { label: $t('common.equals'), value: 'EQ' },
  { label: $t('common.doesNotEqual'), value: 'NE' },
  { label: $t('common.isGreaterThan'), value: 'GT' },
  { label: $t('common.isGreaterThanOrEqualTo'), value: 'GTE' },
  { label: $t('common.isLessThan'), value: 'LT' },
  { label: $t('common.isLessThanOrEqualTo'), value: 'LTE' },
  { label: $t('common.isBetween'), value: 'BETWEEN' }
]

// 全部 操作符数据--仅数字
export const allNumOperatorOptions = [
  { label: 'Equal to', value: 'EQ', icon: 'equal-to', type: 'number' },
  { label: 'More than', value: 'GT', icon: 'more-than', type: 'number' },
  { label: 'Less than', value: 'LT', icon: 'less-than', type: 'number' },
  { label: 'Within range', value: 'BETWEEN', icon: 'within-range', type: 'number' },
  { label: 'Outside range', value: 'UNBETWEEN', icon: 'outside-range', type: 'number' },
  { label: 'Is empty', value: 'IS_NULL', icon: 'is-empty', type: 'number' },
  { label: 'Is not empty', value: 'IS_NOT_NULL', icon: 'is-not-empty', type: 'number' }
]

// 操作符
export const operatorMapping: any = {
  UNCONTAINS: 'is-not',
  CONTAINS: 'is',
  EQ: 'equal-to',
  GT: 'more-than',
  LT: 'less-than',
  BETWEEN: 'within-range',
  UNBETWEEN: 'outside-range',
  IS_NULL: 'is-empty',
  IS_NOT_NULL: 'is-not-empty'
}

// MarketMaps的颜色
export const marketMapColorList = [
  '#2D62EB',
  '#E87F05',
  '#26C282',
  '#F54847',
  '#24A7F1',
  '#F9BF3A',
  '#9912B4',
  '#50B8C7',
  '#3A539A',
  '#039374',
  '#F2784B',
  '#C2392C',
  '#3A539A',
  '#6880A1',
  '#2DCB6F',
  '#F4D03F',
  '#4FCDC5',
  '#6CBAF1',
  '#BD91D5',
  '#F2A9A0'
]

export const unitOptions = [
  { label: $t('common.individuals'), value: 'individuals' },
  { label: $t('common.thousands'), value: 'thousands' },
  { label: $t('common.millions'), value: 'millions' },
  { label: $t('common.billions'), value: 'billions' }
]
interface avatarColorType {
  [key: string]: string[]
}

export const avatarColor: avatarColorType = {
  '0': ['#0B3026', '#57B7C7'],
  '1': ['#0C1A72', '#7696AF'],
  '2': ['#0E1B45', '#A793AC'],
  '3': ['#9F0052', '#D29DAD'],
  '4': ['#7D274C', '#B16A70'],
  '5': ['#BE5B1A', '#F1832C'],
  '6': ['#0B3026', '#B1D8D7'],
  '7': ['#0C1A72', '#C6D1D5'],
  '8': ['#0E1B45', '#D7CCD0'],
  '9': ['#9F0052', '#E9CDCC']
}

export const passphrasePlatForm = ['okx', 'kucoin', 'cactus']

export enum ProjectPermissionLevel {
  FREE = 'FREE',
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
  VIP = 'VIP'
}

export enum ProjectReviewStatus {
  UNACTIVATION = 'UNACTIVATION',
  ACTIVATION = 'ACTIVATION',
  PASS = 'PASS',
  WAIT_DATA_UPDATE = 'WAIT_DATA_UPDATE',
  DATA_UPDATE = 'DATA_UPDATE',
  REJECT = 'REJECT',
  STOP = 'STOP',
  WAIT_PAYMENT = 'WAIT_PAYMENT'
}
